<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              영업일자
            </div>
            <ul class="content">
              <li class="item date">
                <input-date
                  type="lookup-condition"
                  v-model="searchOptions.bsnDate"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="onSearchButtonClick"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="print">
            <erp-button
                button-div="PRINT"
                @click.native="onPrintButtonClicked"
                :is-shortcut-button="true"
            >
              인쇄
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="false"/>
    </div>
  </div>
</template>

<style scoped>
body .content-wrapper .content-body form {margin-top: -21px;}
</style>

<script>
import moment from "moment";

import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import InputDate from "@/components/common/datetime/InputDate";
import ReportView from '@/components/common/report/ReportView';
import ErpButton from "@/components/button/ErpButton.vue";

import {getFormattedDate} from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {commonCodesGetComName} from "@/utils/commonCodes";

export default {
  name: "ReservationFrontDeskWeeklyBusinessReport",
  components: {
    InputDate,
    ErpButton,
    ReportView,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      searchOptions: {
        bsnDate: null,
      },
      reportData: {
        lastWeek: {
          fromDate: null,
          toDate: null,
          dailyStatus: [],
          dailyStatusByPartDiv: []
        },
        thisWeek: {
          fromDate: null,
          toDate: null,
          reservationStatus: []
        }
      },
    };
  },
  created() {
    this.searchOptions.bsnDate = getFormattedDate(new Date());
  },
  computed: {},
  mounted() {
    this.reportInitialSettings();
  },
  methods: {
    reportInitialSettings() {
      const postReportData = {
        reportJson: {
          jsonData: {
            lastWeek: {
              fromDate: null,
              toDate: null,
              dailyStatus: [
                {},
                {},
                {},
                {},
                {},
                {},
                {},
              ],
              dailyStatusByPartDiv: [
                {},
                {},
                {},
                {},
                {},
                {},
                {},
              ]
            },
            thisWeek: {
              fromDate: null,
              toDate: null,
              reservationStatus: [
                {},
                {},
                {},
                {},
                {},
                {},
                {},
              ]
            }
          }
        }
      };

      this.$refs.reportViewComponent.postReport(
        postReportData,
        `${this.$options.name}`,
      );
    },
    async onSearchButtonClick() {
      await this.fetchReservationFrontDeskWeeklyBusinessReport();
    },
    reportDataInit() {
      this.reportData = {
        lastWeek: {
          fromDate: null,
          toDate: null,
          dailyStatus: [],
          dailyStatusByPartDiv: []
        },
        thisWeek: {
          fromDate: null,
          toDate: null,
          reservationStatus: []
        }
      };
    },
    async fetchReservationFrontDeskWeeklyBusinessReport() {
      this.reportDataInit();

      const reportData = await GolfErpAPI.fetchReservationFrontDeskWeeklyBusinessReport(this.searchOptions.bsnDate);

      console.log('reportData.===>', reportData);

      this.reportData.thisWeek.reservationStatus = reportData.thisWeek;

      let bsnDate = reportData?.lastWeekMondayDate.replaceAll("-", "") || null;
      let lastWeekSundayDate = reportData?.lastWeekSundayDate.replaceAll("-", "") || null;

      if (bsnDate && lastWeekSundayDate) {
        while (bsnDate <= lastWeekSundayDate) {
          let dailyStatus = {
            bsnDate: bsnDate,
            dwName: commonCodesGetComName("DW_CODE", reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate)[0]?.dwCode),
            teamCount: reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate).map(data => data.visitTeam).reduce((acc,cur) => acc + cur) || 0,
            visitCount: reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate).map(data => data.visitPer).reduce((acc,cur) => acc + cur) || 0,
            nearbyCount: reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate).map(data => data.nearbyPer).reduce((acc,cur) => acc + cur) || 0,
          };

          dailyStatus.nearbyRatio = dailyStatus.visitCount > 0 ? ((dailyStatus.nearbyCount / dailyStatus.visitCount) * 100).toFixed(1) : 0;

          let dailyStatusByPartDiv = {
            bsnDate: bsnDate,
            dwName: commonCodesGetComName("DW_CODE", reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate)[0]?.dwCode),
            visitCount: reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate).map(data => data.visitPer).reduce((acc,cur) => acc + cur) || 0,
            visitCountByPart1:
              reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "1").length > 0
                ? reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "1").map(data => data.visitPer).reduce((acc,cur) => acc + cur)
                : 0,
            visitCountByPart2:
              reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "2").length > 0
                ? reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "2").map(data => data.visitPer).reduce((acc,cur) => acc + cur)
                : 0,
            visitCountByPart3:
              reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "3").length > 0
                ? reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "3").map(data => data.visitPer).reduce((acc,cur) => acc + cur)
                : 0,
            autoChkinCount : reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate).map(data => data.autoChkinPer).reduce((acc,cur) => acc + cur) || 0,
            autoChkinByPart1:
              reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "1").length > 0
                ? reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "1").map(data => data.autoChkinPer).reduce((acc,cur) => acc + cur)
                : 0,
            autoChkinByPart2:
              reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "2").length > 0
                ? reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "2").map(data => data.autoChkinPer).reduce((acc,cur) => acc + cur)
                : 0,
            autoChkinByPart3:
              reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "3").length > 0
                ? reportData?.lastWeek?.filter(data => data.bsnDate === bsnDate && data.partDiv === "3").map(data => data.autoChkinPer).reduce((acc,cur) => acc + cur)
                : 0,
          };

          dailyStatusByPartDiv.autoChkinRatioByPart1 = dailyStatusByPartDiv.visitCountByPart1 > 0 ? ((dailyStatusByPartDiv.autoChkinByPart1 / dailyStatusByPartDiv.visitCountByPart1) * 100).toFixed(1) : 0;
          dailyStatusByPartDiv.autoChkinRatioByPart2 = dailyStatusByPartDiv.visitCountByPart2 > 0 ? ((dailyStatusByPartDiv.autoChkinByPart2 / dailyStatusByPartDiv.visitCountByPart2) * 100).toFixed(1) : 0;
          dailyStatusByPartDiv.autoChkinRatioByPart3 = dailyStatusByPartDiv.visitCountByPart3 > 0 ? ((dailyStatusByPartDiv.autoChkinByPart3 / dailyStatusByPartDiv.visitCountByPart3) * 100).toFixed(1) : 0;
          dailyStatusByPartDiv.autoChkinRatio = dailyStatusByPartDiv.visitCount > 0 ? ((dailyStatusByPartDiv.autoChkinCount / dailyStatusByPartDiv.visitCount) * 100).toFixed(1) : 0;

          this.reportData.lastWeek.dailyStatus.push(dailyStatus);
          this.reportData.lastWeek.dailyStatusByPartDiv.push(dailyStatusByPartDiv);

          bsnDate = moment(bsnDate).add(1, "days").format("YYYYMMDD");
        }

        const autoChkinCount = this.reportData.lastWeek.dailyStatusByPartDiv.map(data => data.autoChkinCount).reduce((acc,cur) => acc + cur);
        const visitCount = this.reportData.lastWeek.dailyStatusByPartDiv.map(data => data.visitCount).reduce((acc,cur) => acc + cur);

        this.reportData.lastWeek.autoChkinWeekRatio = visitCount > 0 ? (autoChkinCount / visitCount * 100).toFixed(1) : 0;

        const twoWeekAgoAutoChkinCount = reportData.twoWeekAgo.map(data => data.autoChkinPer).reduce((acc,cur) => acc + cur);
        const twoWeekAgoVisitCount = reportData.twoWeekAgo.map(data => data.visitPer).reduce((acc,cur) => acc + cur);

        this.reportData.lastWeek.twoWeekAgoAutoChkinRatio = twoWeekAgoVisitCount > 0 ? (twoWeekAgoAutoChkinCount / twoWeekAgoVisitCount * 100).toFixed(1) : 0;

        this.reportData.lastWeek.fromDate = reportData.lastWeekMondayDate;
        this.reportData.lastWeek.toDate = reportData.lastWeekSundayDate;
        this.reportData.thisWeek.fromDate = reportData.thisWeekMondayDate;
        this.reportData.thisWeek.toDate = reportData.thisWeekSundayDate;
      }

      console.log('this.reportData.===>', this.reportData);
      console.log('this.reportData.===>', JSON.stringify(this.reportData));

      const postReportData = {
        reportJson: {
          jsonData: this.reportData
        }
      };

      this.$refs.reportViewComponent.postReport(
        postReportData,
        `${this.$options.name}`,
      );
    },
    onPrintButtonClicked() {
      this.$refs.reportViewComponent.printReport();
    }
  }
};
</script>
